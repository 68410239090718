import * as React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import {
  GetChannelArchitectureAPI,
  ImportChannelArchitectureAPI,
  ExportChannelArchitectureAPI,
  GetSeriesAPI,
  GetTitleGroupLookupAPI,
} from 'api';
import LinkIcon from 'assets/icons/channel-architecture-linked-slot.svg';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import useStore from 'store/AccountStore';
import { Scheduler } from 'op2mise-react-widgets';
import {
  getWeekNumber,
  formatDateWithMoment,
  addDaysToDate,
  computeTimeDifference,
  getMondayOfTheWeek,
} from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import ModifyBlock from './modals/ModifyBlock';
import { Constants } from './helper/constants';
import { padStart } from '@fullcalendar/react';
import { ActionTypes } from './ChannelArchitecture.d';
import Button from 'shared/components/button/Button';
import DuplicateComponent from './modals/DuplicateComponent';
import { getMondayZeroToSixSchedules } from './utils/functions/getMondayZeroToSixSchedules';

function CAProgrammmes({
  schedulerProps,
  startDate,
  endDate,
  channelInfo,
  calculateDates,
  numberOfWeeks,
  setNumberOfWeeks,
  isDirty,
  setIsDirty,
  schedules,
  dispatch,
  handleSaveChannelArchitecture,
}) {
  const { formTypes, weekdays } = Constants;
  const { user } = useStore((state) => state);
  const instance = new Internationalization();
  const blockData = React.useRef({});
  const [openForm, setOpenForm] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState({});
  const [modalType, setModalType] = React.useState('');
  const [titleGroupList, setTitleGroupList] = React.useState([]);
  const [seriesList, setSeriesList] = React.useState([]);
  const [referenceList, setReferenceList] = React.useState([]);
  const [isClockTypeBroadcast, setClockTypeBroadcast] = React.useState(user?.clockType === 'Broadcast');

  const updateAllItem = (payload, saveChange) => {
    dispatch({
      actionType: ActionTypes.UPDATEALL,
      payload,
      saveChange
    });
  };

  const updateItem = (payload) => {
    dispatch({
      actionType: ActionTypes.UPDATE,
      payload,
    });
    setIsDirty(true);
  };

  const deleteItem = (payload) => {
    dispatch({
      actionType: ActionTypes.DELETE,
      payload,
    });
    setIsDirty(true);
  };

  const insertItem = (payload) => {
    dispatch({
      actionType: ActionTypes.CREATE,
      payload,
    });
    setIsDirty(true);
  };

  const undoItem = () => {
    if (schedules.previous && schedules.previous.length > 0 && isDirty) {
      dispatch({
        actionType: ActionTypes.UNDO,
      });
    }
  };

  const emptyUndo = () => {
    dispatch({
      actionType: ActionTypes.UNDO,
      payload: { previous: [] }
    });
  }

  const getReferenceList = () => {
    const references = schedules.programmes.map((value) => {
      const linked = schedules.programmes.find(
        (x) => x.blockReference == value.blockName
      )
        ? ', Linked'
        : '';
      return {
        id: value.id,
        name: `${value.blockName} (${value.type}${linked})`,
        blockName: value.blockName,
        linked: linked !== '',
      };
    });
    setReferenceList(references);
  };
  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate(x, y) from date.utils
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(new Date())} - ${formatDateWithMoment(
    tomorrow
  )}`;

  const getEndDate = () => {
    return numberOfWeeks > 1
      ? addDaysToDate(startDate, numberOfWeeks * 7)
      : endDate;
  };

  const dateHeaderTemplate = React.useCallback(
    (args) => {
      const day = instance.formatDate(args.date, { skeleton: 'E' });
      // const weekNumber = getWeekNumber(args.date, startDate, endDate);

      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="e-header-day"
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
          >{`${day}`}</div>
        </div>
      );
    },
    [startDate, endDate]
  );

  const eventFields = (args) => ({
    Id: args.id,
    Subject: args.type ?? args.seriesName,
    StartTime: calculateDates(
      args.dayOfWeek,
      args.week,
      args.startTime,
      args.endTime,
      { startDate, endDate }
    )['startTime'], // Field required: Always convert to new Date(...)
    EndTime: calculateDates(
      args.dayOfWeek,
      args.week,
      args.startTime,
      args.endTime,
      { startDate, endDate }
    )['endTime'], // Field required: Always convert to new Date(...)
    IsLinked: args.link,
    IsSequential: args.sequential,
    BlockReference: args.blockReference,
    TitleGroupName: args.titleGroupName,
    SeriesName: args.seriesName,
    BlockName: args.blockName,
    Genre: args.genre,
    Week: args.week,
    DayOfWeek: args.dayOfWeek,
    MaxCount: args.maxCount,
    AvailableDuration: args.availableDuration,
    Layout: args.layout,
    Type: args.type,
    SeasonID: args.seasonID,
    TitleGroupID: args.titleGroupID,
  });

  const eventTemplate = React.useCallback((args) => {
    const {
      State,
      StartTime,
      EndTime,
      Subject,
      IsSequential,
      IsLinked,
      BlockReference,
      TitleGroupName,
      BlockName,
      Genre,
      Week,
      DayOfWeek,
      MaxCount,
      AvailableDuration,
      Layout,
      SeriesName,
      Id,
      Type,
      SeasonID,
      TitleGroupID,
    } = args;
    /** Converts the date value and extracts the time */
    const getTimeString = (value) => {
      return instance.formatDate(value, {
        skeleton: 'hm',
        format: State.timeFormat,
      });
    };

    const getScheduleBlockSubject = () => {
      const subjects = {
        TitleGroup: TitleGroupName,
        Series: SeriesName,
      };
      if (Type === 'Repeat') {
        return TitleGroupName ?? SeriesName;
      }
      return subjects[Subject] ?? Subject;
    };

    return (
      <div
        className="e-schedule-custom-event"
        onContextMenu={() => {
          blockData.current = { ...args };
        }}
        style={{
          position: 'absolute',
          height: '98%',
          width: '98%',
        }}
      >
        <div className="e-schedule-custom-event-title">
          {getScheduleBlockSubject()}
        </div>
        <div className="e-schedule-custom-event-sub-title">
          <div className="e-schedule-custom-event-sub-title-schedule-duration">{`${getTimeString(
            StartTime
          )} - ${getTimeString(EndTime)}`}</div>
        </div>
        {(IsLinked || IsSequential) && (
          <div
            style={{
              position: 'absolute',
              bottom: '-1%',
              marginBottom: '4px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {/* {IsSequential && ( <img className='icon-link-sequential' src={SequentialIcon} alt='sequential-icon' width={16} height={16}/> )} */}
            {IsLinked && (
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <img
                  className="icon-link-sequential"
                  src={LinkIcon}
                  alt="link-icon"
                  width={16}
                  height={16}
                />
                <span
                  style={{
                    color: 'rgba(250, 250, 250, 0.5)',
                    marginLeft: '1px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '80%',
                  }}
                >{`${BlockReference} (${Subject})`}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, []);

  const getMaxWeek = (data) => {
    let maxWeek = 0;
    data.map((programme) => {
      if (programme.week > maxWeek) {
        maxWeek = programme.week;
      }
    });
    setNumberOfWeeks(maxWeek);
  };

  const getBlockName = (day, week, date) => {
    const hour = date.toTimeString().split(':')[0];
    const min = date.toTimeString().split(':')[1];

    const time = `${hour}:${min}`;
    return `${day}${time}`;
  };
  const getSelectedWeek = (e) => {
    if (!e.startTime) return 1;
    const msDay = 24 * 60 * 60 * 1000; // milliseconds per day
    const totalDays = Math.floor(
      (new Date(e.startTime) - new Date(startDate)) / msDay
    );
    const week = Math.floor(totalDays / 7) + 1;
    return week;
  };

  React.useEffect(() => {
    // Close the dialog when the component is unmounted
    setIsDialogOpen(false);
    blockData.current = {};
  }, []);

  const getTime = (time) => {
    return time.toTimeString().split(' ')[0];
  };

  const getIndex = (id) => {
    return schedules.programmes.findIndex((schedule) => schedule.id === id);
  };

  const handleDrag = (args) => {
    const { Id, StartTime, EndTime } = args.data;
    const draggedData = args.draggedData;
    const index = getIndex(Id);
    let newData = {};
    schedules.programmes.map((schedule) => {
      if (schedule.id === Id) {
        newData = {
          ...schedule,
          startTime: getTime(StartTime),
          endTime: getTime(EndTime),
          week: getWeekNumber(StartTime, startDate, endDate),
          availableDuration: computeTimeDifference(
            new Date(StartTime),
            new Date(EndTime)
          ),
          blockName: getBlockName(
            instance.formatDate(new Date(StartTime), { skeleton: 'E' }),
            getWeekNumber(StartTime, startDate, endDate),
            StartTime
          ),
          dayOfWeek:
            weekdays[
            instance.formatDate(new Date(StartTime), { skeleton: 'E' })
            ],
        };
        const draggedSchedule = {
          ...schedule,
          startTime: draggedData.StartTime,
          endTime: draggedData.EndTime,
          availableDuration: computeTimeDifference(
            new Date(draggedData.StartTime),
            new Date(draggedData.EndTime)
          ),
        };
      }
      // return schedule;
    });
    schedules.programmes[index] = newData;
    // updateAllItem({ programmes: data });
  };

  React.useEffect(() => {
    Object.keys(schedules).length !== 0 && getReferenceList();
  }, [schedules]);

  const customFooter = () => {
    return (
      <div style={{ marginTop: '15px', float: 'right' }}>
        <Button
          text="Save"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            emptyUndo()
            handleSaveChannelArchitecture()
          }}
          disabled={!isDirty}
        />
      </div>
    );
  };

  const handleDuplicatedSlot = (args) => {
    if (args.Id) {
      setSelectedSlot({ ...args });
      setOpenForm(formTypes.paste);
    }
  };

  const getUpdatedList = (channelId) => {
    GetChannelArchitectureAPI({
      queryParams: { channelId },
      onSuccess: (response) => {
        updateAllItem({ programmes: isClockTypeBroadcast ? getMondayZeroToSixSchedules(response, startDate, endDate) : response });
      },
      setLoader: setIsLoading,
    });
  };

  const programmeSchedulerProps = React.useMemo(
    () => ({
      ...schedulerProps,
      dateHeaderTemplate,
      dataSource: schedules,
      eventFields,
      eventLookupList: 'programmes',
      eventTemplate,
      schedule: { start: startDate, end: endDate },
      loading: isLoading,
      onImport: () => {
        setOpenForm(formTypes.import);
      },
      onExport: () => {
        ExportChannelArchitectureAPI({
          queryParams: { channelId },
          onSuccess: (res) => {
            const { organisationName } = user;
            let exportDate = new Date();
            const exportedDateString =
              padStart(exportDate.getDate(), 2).toString() +
              padStart(exportDate.getMonth() + 1, 2).toString() +
              exportDate.getFullYear().toString();
            const fileName = `${organisationName}_op2mise_channel_architecture_programme_export_${channelName}_${exportedDateString}.xlsx`;
            const blob = new Blob([res], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, fileName);
          },
        });
      },
      onSlotModified: () => {
        setIsDialogOpen(true);
        setModalType(ActionTypes.UPDATE);
      },
      onSlotDeleted: (args) => {
        deleteItem(args);
      },
      onSlotDragStop: handleDrag,
      onSlotResizeStop: handleDrag,
      onSlotDuplicated: (args) => handleDuplicatedSlot(args),
      onCellDoubleClick: (e) => {
        blockData.current = {
          EndTime: e.endTime,
          StartTime: e.startTime,
          Type: 'Movies',
          Layout: 'Sequential',
          Week: getSelectedWeek(e),
        };
        setModalType(ActionTypes.CREATE);
        setIsDialogOpen(true);
      },
      settings: ['SLOT DURATION', 'SLOT INTERVAL', 'CLOCKTYPES'],
      suppressOverlappingSchedules: true,
      suppressTooltip: true,
      suppressQuickInfo: true,
      timeoutDelay: 800,
      actionButtonPanel: customFooter,
      heightBuffer: 230,
      onUndo: undoItem,
    }),
    [
      schedules,
      channelInfo,
      startDate,
      endDate,
      isDirty,
      isLoading
    ]
  );

  React.useEffect(() => {
    if (channelId || isDirty) {
      GetChannelArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          updateAllItem({ programmes: isClockTypeBroadcast ? getMondayZeroToSixSchedules(response, startDate, endDate) : response });
          getMaxWeek(response);
          setIsDirty(false);
        },
        setLoader: setIsLoading,
      });
      GetSeriesAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          setSeriesList(response.series);
        },
        setLoader: setIsLoading,
      });
      GetTitleGroupLookupAPI({
        onSuccess: (response) => {
          setTitleGroupList(response);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, isClockTypeBroadcast]);

  const RenderScheduler = React.useCallback(() => {
    return channelId === 0 && !channelName ? null : (
      <Scheduler {...programmeSchedulerProps} />
    );
  }, [programmeSchedulerProps]);

  const blockProps = {
    schedules,
    setIsDialogOpen,
    blockData: blockData.current,
    numberOfWeeks,
    setNumberOfWeeks,
    modalType,
    titleGroupList,
    seriesList,
    updateItem,
    referenceList,
    insertItem,
  };

  React.useEffect(() => {
    // Change between Standard and Broadcast clocktype
    setClockTypeBroadcast(user?.clockType === 'Broadcast');
  }, [user]);

  React.useEffect(() => {
    if (numberOfWeeks === 0) setNumberOfWeeks(1);
  }, [numberOfWeeks]);

  React.useEffect(() => {
    if (!schedules.previous || schedules.previous.length === 0)
      setIsDirty(false);
  }, [schedules.previous]);


  return (
    <>
      {isDialogOpen && <ModifyBlock {...blockProps} />}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm(false)}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            schedules: schedules.programmes,
            period: { startTime: startDate, endTime: getEndDate() },
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="programmeImportTemplate"
          handleOnImportApi={ImportChannelArchitectureAPI}
          importResultColumns={[
            {
              field: 'dayOfWeek',
              headerText: 'Day of Week',
            },
            {
              field: 'startTime',
              headerText: 'Start Time',
            },
            {
              field: 'endTime',
              headerText: 'End Time',
            },
            {
              field: 'type',
              headerText: 'Type',
            },
            {
              field: 'genre',
              headerText: 'Genre',
            },
          ]}
          titleGroupList={titleGroupList}
          seriesList={seriesList}
          importResultFileTitle="programme_import_result"
          // getUpdatedList={getUpdatedList}
          setSchedules={(data) => {
            // Include import action to undo list
            updateAllItem({ programmes: isClockTypeBroadcast ? getMondayZeroToSixSchedules(data, startDate, endDate) : data }, true)
            setIsDirty(true);
            setIsLoading(false);
          }}
        />
      )}
      {openForm === formTypes.paste && (
        <DuplicateComponent
          schedules={schedules}
          startDate={startDate}
          endDate={endDate}
          setNumberOfWeeks={setNumberOfWeeks}
          channelId={channelId}
          selectedSlot={selectedSlot}
          onClose={() => setOpenForm('')}
          onPaste={(args) => {
            if (args?.length > 1) {
              args.map((arg) => {
                insertItem({ item: [arg] });
              });
            }
            if (args?.id) insertItem({ item: [args] });
          }}
          numberOfWeeks={numberOfWeeks} // Used for the Recurrence Pattern Component
          referenceList={referenceList}
        />
      )}
      {isLoading ? (
        <div style={{ height: 'calc(100vh - 100px)' }}>
          <BootstrapSpinner />
        </div>
      ) : (
        <>
          <RenderScheduler />
        </>
      )}
    </>
  );
}

export default CAProgrammmes;
