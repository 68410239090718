import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../shared/components/checkbox/Checkbox';
import SelectComponent from '../../../../shared/elements/select/select.component';
import styles from './ScheduleForms.module.css';
import CustomTimeInput from 'shared/components/custom-time-input/CustomTimeInput';
import { DatePicker } from 'op2mise-react-widgets';
import { setSecondsToZero } from 'utils';

const ReccurencePatternComponent = ({
  isRegular = false,
  numberOfSlots,
  onChangeRecurrence,
  patterns = [
    { id: 1, name: 'Consecutively' },
    { id: 2, name: 'Daily' },
    { id: 3, name: 'Weekly' },
    { id: 4, name: 'Custom' },
  ],
  type = null,
  numberOfWeeks = 1,
  dateRange = { start: new Date(), end: new Date() },
  timeValue,
}) => {
  const days = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];
  const inputTypeNumberInvalidInputs = ['.', '-', '+', 'e'];

  const daysAbbreviated = [
    { id: 1, name: 'Mon' },
    { id: 2, name: 'Tue' },
    { id: 3, name: 'Wed' },
    { id: 4, name: 'Thu' },
    { id: 5, name: 'Fri' },
    { id: 6, name: 'Sat' },
    { id: 7, name: 'Sun' },
  ];

  const dailyPatternDefaultValues = () => {
    const values = {
      reccurencePatternId: 2,
      intervalId: 1,
      intervalValue: 1,
      week: { id: 0, name: 'Every Week' },
    };
    return values;
  };

  const weeklyPatternDefaultValues = () => {
    const values = {
      reccurencePatternId: 3,
      everyWeekInterval: 1,
      days: days.map((day) => day.id),
    };
    return values;
  };

  const customPatternDefaultValues = () => {
    const values = {
      reccurencePatternId: 4,
      everyWeekInterval: 1,
      days: daysAbbreviated.map((day) => day.id),
      period: dateRange,
      slots: daysAbbreviated.map((day) => {
        return {
          id: day.id,
          startTime: setSecondsToZero(timeValue),
          endTime: null,
          programs: null,
          radioValue: 'EndTime',
        };
      }),
    };
    return values;
  };
  const [selectedPattern, setSelectedPattern] = useState([]);
  const [parameters, setParameters] = useState({});

  const onSelectPattern = (pattern) => {
    setSelectedPattern(pattern);
    switch (pattern.id) {
      case 1:
        setParameters({});
        break;
      case 2:
        setParameters(dailyPatternDefaultValues());
        break;
      case 3:
        setParameters(weeklyPatternDefaultValues());
        break;
      case 4:
        setParameters(customPatternDefaultValues());
        break;
      default:
        break;
    }
  };

  const onSelectRadio = (id) => {
    setParameters({ ...parameters, intervalId: id });
  };

  const onChangeParameters = (e) => {
    let value = e.target.value;
    if (value > 99) value = 99;

    setParameters({ ...parameters, [e.target.name]: value });
  };

  const sortDays = (a, b) => {
    if ((a === 0) != (b === 0)) {
      return a === 0 ? 1 : -1;
    }
    return a > b ? 1 : a < b ? -1 : 0;
  };

  const onCheckDays = (dayId) => {
    if (parameters.days.includes(dayId)) {
      const remainingDayIds = parameters.days.filter((day) => day !== dayId);
      setParameters({ ...parameters, days: remainingDayIds });
    } else {
      const sortedDays = [...parameters.days, dayId].sort((a, b) =>
        sortDays(a, b)
      );
      setParameters({ ...parameters, days: sortedDays });
    }
  };

  const weekDropdown = () => {
    const weeks = [...Array(numberOfWeeks).keys()].map((week) => ({
      id: week + 1,
      name: `Week ${week + 1}`,
    }));
    weeks.push({ id: 0, name: 'Every Week' });
    return weeks;
  };

  const dailyPattern = () => {
    return (
      <div className="d-flex">
        <div className={styles.formCheckWrapper}>
          <input
            className={`${styles.formCheck} form-check-input`}
            type="radio"
            checked={parameters.intervalId === 1}
            onChange={() => onSelectRadio(1)}
          />
          <label className={styles.label} style={{ paddingRight: '15px' }}>
            Every
          </label>
          {type === 'dropdown' ? (
            <div>
              <SelectComponent
                text={days[parameters.intervalValue - 1].name}
                list={[...days].splice(0, 7)}
                width="120px"
                onSelect={(value) => {
                  setParameters({
                    ...parameters,
                    intervalValue: value.id,
                  });
                }}
                disabled={parameters.intervalId !== 1}
                optionStyle={styles.recurrenceOptions}
              />
            </div>
          ) : (
            <>
              <input
                className={`${styles.formInput} ${parameters.intervalValue <
                  1 && styles.formInputRedBorder}`}
                type="number"
                name="intervalValue"
                value={parameters.intervalValue}
                min={1}
                max={99}
                onChange={onChangeParameters}
                onKeyDown={(e) =>
                  inputTypeNumberInvalidInputs.includes(e.key) &&
                  e.preventDefault()
                }
                disabled={parameters.intervalId !== 1}
              />
              <label className={styles.label}>day(s)</label>
            </>
          )}
        </div>

        <div className={styles.formCheckWrapper}>
          <input
            className={`${styles.formCheck} form-check-input`}
            type="radio"
            checked={parameters.intervalId === 2}
            onChange={() => onSelectRadio(2)}
          />
          <label className={styles.label}>Every weekday</label>
        </div>

        <div className={styles.formCheckWrapper}>
          <input
            className={`${styles.formCheck} form-check-input`}
            type="radio"
            checked={parameters.intervalId === 3}
            onChange={() => onSelectRadio(3)}
          />
          <label className={styles.label} style={{ paddingRight: '15px' }}>
            Every weekend of
          </label>
          <div>
            <SelectComponent
              text={numberOfWeeks ? `${parameters.week.name}` : 'Every week'}
              list={weekDropdown}
              width="154px"
              onSelect={(value) => {
                setParameters({
                  ...parameters,
                  week: value,
                });
              }}
              //  disabled={parameters.intervalId !== 3}
              optionStyle={styles.recurrenceOptions}
            />
          </div>
        </div>
      </div>
    );
  };

  const weeklyPattern = () => {
    return (
      <div>
        <div className={styles.weeklyPatternLabelWrapper}>
          <label className={styles.label}>Recur every</label>
          <input
            className={`${styles.formInput} ${parameters.everyWeekInterval <
              1 && styles.formInputRedBorder}`}
            type="number"
            name="everyWeekInterval"
            value={parameters.everyWeekInterval}
            onChange={onChangeParameters}
            onKeyDown={(e) =>
              inputTypeNumberInvalidInputs.includes(e.key) && e.preventDefault()
            }
          />
          <label className={styles.label}>week(s) on:</label>
        </div>

        <div className={styles.weekdaysWrapper}>
          {days.map((day) => (
            <Checkbox
              key={day.id}
              label={day.name}
              active={parameters.days.includes(day.id)}
              onCheck={() => onCheckDays(day.id)}
              fontClassName={styles.checkboxLabel}
              checkboxClassName={styles.checkbox}
            />
          ))}
        </div>
      </div>
    );
  };

  const changeCustomValues = (id, attr, value) => {
    const { slots } = parameters;
    let dayIndex = slots.findIndex((o) => o.id === id);
    slots[dayIndex] = { ...slots[dayIndex], [attr]: value };
    setParameters({
      ...parameters,
      slots: slots,
    });
  };

  const changePeriod = (attr, value) => {
    if (attr === 'start') {
      setParameters({
        ...parameters,
        period: { ...parameters.period, start: value },
      });
    }
    if (attr === 'end') {
      setParameters({
        ...parameters,
        period: { ...parameters.period, end: value },
      });
    }
  };

  const customPattern = () => {
    const isChecked = (id, value) => {
      return parameters.slots.find((o) => o.id === id).radioValue === value;
    };
    return (
      <div style={{ width: '100%', marginLeft: '10px' }}>
        <div
          style={{
            marginBottom: '6px',
            display: 'flex',
          }}
        >
          <DatePicker
            allowEdit={false}
            cssClass="view-schedules-date-picker"
            format={'dd/MM/yyyy'}
            label={'Period from:'}
            defaultValue={dateRange.start}
            min={dateRange.start}
            max={dateRange.end}
            onChange={(e) => {
              changePeriod('start', e.target.value);
            }}
            showClearButton={false}
            placeholder="dd/MM/yyyy"
            value={dateRange.start}
          />
          <DatePicker
            allowEdit={false}
            cssClass="view-schedules-date-picker"
            format={'dd/MM/yyyy'}
            label="To:"
            defaultValue={dateRange.end}
            min={dateRange.start}
            max={dateRange.end}
            onChange={(e) => {
              changePeriod('end', e.target.value);
            }}
            showClearButton={false}
            placeholder="dd/MM/yyyy"
            value={dateRange.end}
          />
        </div>
        <table style={{ width: '100%' }}>
          <tr>
            <td> </td>
            {daysAbbreviated.map((day) => (
              <td>
                <Checkbox
                  key={day.id}
                  label={day.name}
                  active={parameters.days.includes(day.id)}
                  onCheck={() => onCheckDays(day.id)}
                  fontClassName={styles.checkboxLabel}
                  checkboxClassName={styles.checkbox}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td>Start time</td>
            {daysAbbreviated.map((day, index) => (
              <td>
                <div
                  className={`${styles.customTDWidth}`}
                  style={{ marginLeft: '18px', width: '66px' }}
                >
                  <CustomTimeInput
                    defaultValue={parameters.slots[index].startTime}
                    disabled={!parameters.days.includes(day.id)}
                    onChange={(e) => {
                      const defaultStart = parameters.slots[index].startTime;
                      const startTime = new Date(
                        defaultStart.getFullYear(),
                        defaultStart.getMonth(),
                        defaultStart.getDate(),
                        e.getHours(),
                        e.getMinutes,
                        0
                      );
                      changeCustomValues(day.id, 'startTime', startTime);
                    }}
                  />
                </div>
              </td>
            ))}
          </tr>

          <tr>
            <td>End time</td>
            {daysAbbreviated.map((day, index) => (
              <td>
                <div className={`${styles.customTDWidth}`}>
                  <input
                    type="radio"
                    name={`custom_${day.id}`}
                    value={`EndTime`}
                    style={{
                      width: '30px',
                      marginRight: '5px',
                      accentColor: '#008662',
                    }}
                    disabled={!parameters.days.includes(day.id)}
                    onChange={(e) => {
                      changeCustomValues(day.id, 'radioValue', e.target.value);
                    }}
                    checked={isChecked(day.id, 'EndTime')}
                  />
                  <CustomTimeInput
                    disabled={
                      !parameters.days.includes(day.id) ||
                      !isChecked(day.id, 'EndTime')
                    }
                    onChange={(e) => {
                      const defaultStart = parameters.slots[index].startTime;
                      const endTime = new Date(
                        defaultStart.getFullYear(),
                        defaultStart.getMonth(),
                        defaultStart.getDate(),
                        e.getHours(),
                        e.getMinutes(),
                        0
                      );
                      changeCustomValues(day.id, 'endTime', endTime);
                    }}
                  />
                </div>
              </td>
            ))}
          </tr>

          <tr>
            <td>Programs</td>
            {daysAbbreviated.map((day) => (
              <td>
                <div className={`${styles.customTDWidth}`}>
                  <input
                    type="radio"
                    name={`custom_${day.id}`}
                    value="Programs"
                    style={{
                      width: '30px',
                      marginRight: '5px',
                      accentColor: '#008662',
                    }}
                    disabled={!parameters.days.includes(day.id)}
                    onChange={(e) => {
                      changeCustomValues(day.id, 'radioValue', e.target.value);
                    }}
                  />
                  <input
                    className={`${styles.recurrenceFormInput
                      } ${parameters.everyWeekInterval < 1 &&
                      styles.formInputRedBorder}`}
                    type="number"
                    name="everyWeekInterval"
                    onChange={(e) => {
                      changeCustomValues(day.id, 'programs', e.target.value);
                    }}
                    onKeyDown={(e) =>
                      inputTypeNumberInvalidInputs.includes(e.key) &&
                      e.preventDefault()
                    }
                    disabled={
                      !parameters.days.includes(day.id) ||
                      !isChecked(day.id, 'Programs')
                    }
                  />
                </div>
              </td>
            ))}
          </tr>
        </table>
      </div>
    );
  };

  useEffect(() => {
    setSelectedPattern(!isRegular ? patterns[1] : patterns[0]);
    setParameters(!isRegular ? dailyPatternDefaultValues() : {});
  }, [isRegular]);

  useEffect(() => {
    onChangeRecurrence(parameters);
  }, [parameters]);

  useEffect(() => {
    if (!patterns.find((o) => o.id === 1)) {
      setParameters(dailyPatternDefaultValues());
    }
  }, []);

  return (
    <>
      <div className={`${styles.numberOfSlotsLabel} mx-1 mt-3`}>
        Recurrence pattern
        <span style={type === 'dropdown' ? { marginRight: '15px' } : {}}></span>
        {!isRegular && `(${numberOfSlots} slots will be created)`}
      </div>

      <div className="d-flex mx-1">
        <div>
          <SelectComponent
            text={selectedPattern.name}
            list={
              !isRegular
                ? patterns.filter((pattern) => pattern.id !== 1)
                : patterns
            }
            width="120px"
            // buttonStyle={{
            //   height: '40px',
            //   padding: '0px 12px',
            //   lineHeight: '40px',
            // }}
            onSelect={onSelectPattern}
            optionStyle={styles.recurrenceOptions}
          />
        </div>

        {selectedPattern.id === 2 && dailyPattern()}

        {selectedPattern.id === 3 && weeklyPattern()}

        {selectedPattern.id === 4 && customPattern()}
      </div>
    </>
  );
};

export default ReccurencePatternComponent;
